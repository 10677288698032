<template>
  <div>
<!--    <v-row v-if="addingSensor">-->
<!--      <v-col>-->
<!--        <div style="height: 700px; z-index: 0">-->
<!--          <h4>Click a marker to remove a sensor, click an empty map position to create a new sensor</h4>-->
<!--          <l-map-->
<!--              style="z-index: 0"-->
<!--              :zoom="zoom"-->
<!--              :center="center"-->
<!--              @update:zoom="zoomUpdated"-->
<!--              @update:center="centerUpdated"-->
<!--              @update:bounds="boundsUpdated"-->
<!--              @click="addMarker"-->
<!--          >-->
<!--            <l-tile-layer :url="url"></l-tile-layer>-->
<!--            <l-marker v-if="sensors" v-for="(sensor, index) in sensors" :lat-lng="[sensor.lat, sensor.lng]"-->
<!--                      :options="{title: sensor.name}" @click="removeMarker(index)">-->
<!--              <l-popup>{{ sensor.name }}<br>ANPR Camera</l-popup>-->
<!--            </l-marker>-->
<!--          </l-map>-->
<!--        </div>-->
<!--      </v-col>-->
<!--    </v-row>-->
    <v-row>
      <v-col cols="12">
        <base-card>
          <v-card-title>
            <div class="d-flex justify-space-between flex-wrap">

              <v-btn color="success" @click="create()">
                Add new
              </v-btn>
            </div>
          </v-card-title>
          <v-card-title>
            <strong>
              <v-icon x-large>mdi-leak</v-icon>
            </strong> Sensors
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
              v-model="selected"
              :search="search"
              :headers="headers"
              :items="sensors"
              item-key="id"
              show-select
              class="elevation-1 table-one"
              multi-sort
          >
            <template v-slot:item.name="{item}">
              <div class="d-flex align-center">
                <p class="ma-0 font-weight-medium">
                  {{ item.name }}
                </p>
              </div>
            </template>
            <template v-slot:item.occupancy="{item}">
              <div class="d-flex">

                <template>
                  <v-progress-linear
                      :value="item.occupancy"
                      width="50"
                      height="10"
                      striped
                      color="deep-orange"
                  />
                </template>
              </div>
            </template>
            <template v-slot:item.action="{item}">
              <div class="d-flex">
                <v-tooltip top>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn
                        color="success"
                        dark
                        @click="edit(item.id)"
                        icon
                    >
                      <v-icon>mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn
                        color="danger"
                        dark
                        @click="remove(item.id)"
                        icon
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </base-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {LMap, LMarker, LPopup, LTileLayer} from "vue2-leaflet";

import {mapActions, mapGetters, mapState} from "vuex";
import toastr from "toastr";

export default {
  name: "sensor-list",
  metaInfo: {
    title: 'Sensors'
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  data() {
    return {
      // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      // zoom: 18,
      // center: [51.48819, -0.31524],
      // bounds: null,
      // editing: {},
      // sensors: [],
      search: '',
      selected: [],
      headers: [
        {
          text: 'Name',
          sortable: true,
          value: 'name',
          align: 'left',
        },
        {
          text: 'Lat',
          sortable: true,
          value: 'lat',
          align: 'left',
        },
        {
          text: 'Lng',
          sortable: true,
          value: 'lng',
          align: 'left',
        },
        {
          text: 'Actions',
          value: 'action',
        }
      ],
    }
  },
  computed: {
    ...mapState('sensors', {loading : 'isFindPending'}),
    ...mapGetters('sensors', {findSensorsInStore : 'find'}),
    sensors() {
      return this.findSensorsInStore().data || [];
    }
  },
  methods: {
    ...mapActions('sensors', { findCarParks: 'find' }),

    create() {
      this.$router.push({name: 'sensor-create'})
    },
    edit(id) {
      this.$router.push({name: 'sensor-edit', params: { id: id }})
    },
    view(id) {
      this.$router.push({name: 'sensor-view', params: { id: id }})
    },
    remove(id) {
      console.log(`Trying to remove ${id}`)
      this.$store.dispatch('sensor/remove', id).then((result) => {
        toastr.success('Successfully removed a sensor.', 'Success')
      }).catch((error) => {// Display an error toast, with a title
        toastr.error('Failed to remove the selected sensor', 'Error')
        console.error(error);
      })
    },
  },
  mounted() {
    this.findCarParks({ query: {}});
  }
}
</script>
<style lang="scss" scoped>
::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }

      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }

    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }
}
</style>
